import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import invoiceFunctions, {
  getPaymentStatusText,
  Invoice,
  InvoicePaymentStatus,
  Value,
} from '../model/invoice';
import { SearchParams } from '../model/searchParams';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '../utils/constants';
import { DefaultBackendHelper } from '../utils/backendHelper';

const logger = new Logger('backend.Invoices');

export interface BackendInvoice {
  getInvoices: (
    searchParams: SearchParams,
    attendeeAppUserId?: string,
    filterByStati?: InvoicePaymentStatus[],
    dateFrom?: string,
    dateTill?: string,
    locationId?: string,
    departmentId?: string
  ) => AxiosPromise<Value>;
  updateInvoice: (invoice: Invoice) => AxiosPromise<any>;
  exportCompleteInvoices: (
    markInvoicesPaid: boolean,
    exportInSingleFile: boolean,
    culture: string
  ) => AxiosPromise<any>;
}

export const DefaultBackendInvoice: BackendInvoice = {
  getInvoices(
    searchParams: SearchParams,
    attendeeAppUserId?: string,
    filterByStati?: InvoicePaymentStatus[],
    dateFrom?: string,
    dateTill?: string,
    locationId?: string,
    departmentId?: string
  ): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    if (attendeeAppUserId != undefined) {
      odfb.eq(CONST.AttendeeAppUserId, attendeeAppUserId, false);
    }

    if (dateFrom != undefined) {
      odfb.ge(CONST.PeriodStartDate, dateFrom, false);
    }

    if (filterByStati != undefined && filterByStati.length > 0) {
      let odfbStati = ODataFilterBuilder('or');
      filterByStati.forEach((x) =>
        odfbStati.eq(CONST.PaymentStatus, getPaymentStatusText(x))
      );
      odfb.and(odfbStati);
    }

    if (dateTill != undefined) {
      odfb.le(CONST.PeriodTillDate, dateTill, false);
    }

    let baseUrl =
      locationId != undefined
        ? `${URLS.invoiceOdata}/GetInLocation(locationId=${locationId})`
        : departmentId != undefined
        ? `${URLS.invoiceOdata}/GetInDepartment(departmentId=${departmentId})`
        : URLS.invoiceOdata;

    let url = DefaultBackendHelper.makeUrl(
      baseUrl,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );

    logger.log(`getInvoices${url}`);
    return instance.get<Value>(url);
  },
  updateInvoice(invoice: Invoice): AxiosPromise<any> {
    return instance.put<Invoice>(
      `${URLS.invoice}/${invoice.id}`,
      invoiceFunctions.toAPI(invoice)
    );
  },
  exportCompleteInvoices(
    markInvoicesPaid: boolean,
    exportInSingleFile: boolean,
    culture: string
  ): AxiosPromise<any> {
    let url = `${URLS.invoice}/ExportCompleteInvoices`;
    url = DefaultBackendHelper.addParamsToUrl(url, [`culture=${culture}`]);
    return instance.post(
      url,
      {
        MarkExportedInvoicesPaid: markInvoicesPaid,
        ExportInSingleFile: exportInSingleFile,
      },
      { responseType: 'blob' }
    );
  },
};
